<template>
  <div>
    <ion-button size="large" color="light" :router-link="(link) ? link : ''">
      <ion-icon slot="icon-only" :src="icon"> </ion-icon>
    </ion-button>
    <div class="h6 margin-top-8">
      {{ label }}
    </div>
  </div>
</template>

<script>
import { IonButton, IonIcon } from "@ionic/vue";

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "/assets/diaper.svg"
    },
    link: {
      type: String,
      default: null
    }
  },

  components: {
    IonButton,
    IonIcon
  },
};
</script>