<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home">
          </ion-back-button>
        </ion-buttons>
        <ion-title>Scanner</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <section class="ion-padding background-white">
        <ion-grid>
          <ion-row>
            <ion-col size="4" class="ion-text-center">
              <activity-button label="Collect Breastmilk" icon="/assets/breastmilk_received.svg"
                link="/scan/ebmin"></activity-button>
            </ion-col>

            <ion-col size="4" class="ion-text-center">
              <activity-button label="Prepare Bottle" icon="/assets/baby_bottle_prep.svg"
                link="/scan/preparebottle"></activity-button>
            </ion-col>

            <ion-col size="4" class="ion-text-center">
              <activity-button label="Feed Breastmilk" icon="/assets/baby_bottle.svg"
                link="/scan/ebmout"></activity-button>
            </ion-col>

            <ion-col size="12">
              <hr>
            </ion-col>

            <ion-col size="4" class="ion-text-center">
              <activity-button label="Baby In Room" icon="/assets/baby_in_guest_room.svg"
                link="/scan/babyinroom"></activity-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonBackButton, IonGrid, IonRow, IonCol } from "@ionic/vue";
import ActivityButton from "@/components/users/activities/ActivityButton.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonBackButton, IonGrid, IonRow, IonCol, ActivityButton
  },

  computed: {
    ...mapGetters(["authUser", "version"]),
  },

  data() {
    return {
    };
  },

  ionViewWillEnter() {
  },

  methods: {
  },
};
</script>

<style scoped>
ion-toolbar {
  --background: #fff;
}

.square-button {
  width: 150px;
  height: 150px;
  white-space: normal;
}
</style>